import React, { useEffect, useRef, useState } from 'react';

import { Table, Card, Button, Spin, message, Col } from 'antd';
import _ from 'lodash';
import GeoJSON from 'ol/format/GeoJSON';
import handlebars from 'handlebars/dist/handlebars.min.js';

import { getSiteValueLocations, getSiteValueInfo, getAttachmentSignedUrl, getComments, getActivities } from './actions'
import { zoomToPoints, getMapInstance, createDraftFeatureLayer, addLayerSwitcher, getPointStyleFunction } from '../utils/map-utils';
import { SITE_VALUE_TYPES, SITE_VALUE_RECORD_TYPE } from '../utils/constants';
import { resolveTemplate, formatDateTimeToDisplay, formatDateToDisplay } from '../utils/common-utils'
import { RecordViewerModal } from './RecordViewerModal';

export const AudubonSiteValuesMapView = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [selectedSiteValueId, setSelectedSiteValueId] = useState();
    const [orgId, setOrgId] = useState();
    const [orgUnitId, setOrgUnitId] = useState();
    const [certificate, setCertificate] = useState();
    const [recordViewInner, setRecordViewInner] = useState('<div/>');
    const [recordViewerVisible, setRecordViewerVisible] = useState(false);

    const [map, setMap] = useState();
    const [siteValuesList, setSiteValuesList] = useState();

    const mapRef = useRef();

    useEffect(() => {
        setOrgId(props.orgId);
        setOrgUnitId(props.orgUnitId);
        setCertificate(props.certificate)
        // load locations
        loadLocations(props.orgId, props.orgUnitId, props.certificate);
    }, [props.orgId, props.orgUnitId, props.certificate]);

    const loadLocations = async (orgId, orgUnitId, certificate) => {
        const locations = await getSiteValueLocations(orgId, orgUnitId, SITE_VALUE_RECORD_TYPE.AUDUBON,  certificate);
        setSiteValuesList(locations);
        const locationDataMap = locations.reduce((locationDataMap, location) => {
            const type = location.type;
            location.geom = JSON.parse(location.geom);
            if (!locationDataMap[type]) {
                locationDataMap[type] = [];
            }
            locationDataMap[type].push(location);
            return locationDataMap;
        }, {});
        setIsLoading(false);

        // draw map
        const draftFeatureLayer = createDraftFeatureLayer(getPointStyleFunction);
        const airFeatureLayer = createDraftFeatureLayer(getPointStyleFunction, 'Air');
        const audubonFeatureLayer = createDraftFeatureLayer(getPointStyleFunction, 'Audubon');
        const culturalSignificanceFeatureLayer = createDraftFeatureLayer(getPointStyleFunction, 'Cultural Significance');
        const infrastructureFeatureLayer = createDraftFeatureLayer(getPointStyleFunction, 'Infrastructure');
        const energyFeatureLayer = createDraftFeatureLayer(getPointStyleFunction, 'Energy');
        const managedTurfAreasFeatureLayer = createDraftFeatureLayer(getPointStyleFunction, 'Managed Turf Areas');
        const unSDGFeatureLayer = createDraftFeatureLayer(getPointStyleFunction, 'Our SDGs');
        const vegetationAndHabitatFeatureLayer = createDraftFeatureLayer(getPointStyleFunction, 'Vegetation and Habitat');
        const wasteFeatureLayer = createDraftFeatureLayer(getPointStyleFunction, 'Waste');
        const waterFeatureLayer = createDraftFeatureLayer(getPointStyleFunction, 'Water');
        const wildlifeFeatureLayer = createDraftFeatureLayer(getPointStyleFunction, 'Wildlife');
        const healthAndSafetyLayerFeatureLayer = createDraftFeatureLayer(getPointStyleFunction, 'Health and Safety');
        const developmentGrowthLayerFeatureLayer = createDraftFeatureLayer(getPointStyleFunction, 'Decent Work');
        const qualityEducationLayerFeatureLayer = createDraftFeatureLayer(getPointStyleFunction, 'Quality Education');
        const genderEqualityLayerFeatureLayer = createDraftFeatureLayer(getPointStyleFunction, 'Gender Equality');
        const partnershipLayerFeatureLayer = createDraftFeatureLayer(getPointStyleFunction, 'Partnerships');
        const reduceInequalityLayerFeatureLayer = createDraftFeatureLayer(getPointStyleFunction, 'Reduce Inequalities');
        const heritageLayerFeatureLayer = createDraftFeatureLayer(getPointStyleFunction, 'Heritage');

        if (!map) {
            var newMap = getMapInstance({
                draftFeaturesLayer: draftFeatureLayer,
                mapRef: mapRef
            });
            setMap(newMap);

            newMap.addLayer(heritageLayerFeatureLayer);
            newMap.addLayer(reduceInequalityLayerFeatureLayer);
            newMap.addLayer(partnershipLayerFeatureLayer);
            newMap.addLayer(genderEqualityLayerFeatureLayer);
            newMap.addLayer(qualityEducationLayerFeatureLayer);
            newMap.addLayer(developmentGrowthLayerFeatureLayer);
            newMap.addLayer(wildlifeFeatureLayer);
            newMap.addLayer(waterFeatureLayer);
            newMap.addLayer(wasteFeatureLayer);
            newMap.addLayer(vegetationAndHabitatFeatureLayer);
            newMap.addLayer(managedTurfAreasFeatureLayer);
            newMap.addLayer(unSDGFeatureLayer);
            newMap.addLayer(infrastructureFeatureLayer);
            newMap.addLayer(healthAndSafetyLayerFeatureLayer);
            newMap.addLayer(energyFeatureLayer);
            newMap.addLayer(culturalSignificanceFeatureLayer);
            newMap.addLayer(audubonFeatureLayer);
            newMap.addLayer(airFeatureLayer);
            addLayerSwitcher(newMap);
            zoomToPoints(newMap, draftFeatureLayer);

            newMap.on('click', async(evt) => {
                const f = newMap.forEachFeatureAtPixel(
                    evt.pixel, (ft, layer) => { return ft; }
                );

                if (f) {
                    if (!f.get('type')) return; // new ones, just skip
                    await downloadReviewRecordURL(orgId, orgUnitId, certificate, f.get('id'));
                }
            });
        }
        addFeaturesToLayer('air', airFeatureLayer, locationDataMap);
        addFeaturesToLayer('audubon', audubonFeatureLayer, locationDataMap);
        addFeaturesToLayer('cultural_significance', culturalSignificanceFeatureLayer, locationDataMap);
        addFeaturesToLayer('infrastructure', infrastructureFeatureLayer, locationDataMap);
        addFeaturesToLayer('energy', energyFeatureLayer, locationDataMap);
        addFeaturesToLayer('water', waterFeatureLayer, locationDataMap);
        addFeaturesToLayer('waste', wasteFeatureLayer, locationDataMap);
        addFeaturesToLayer('wildlife', wildlifeFeatureLayer, locationDataMap);
        addFeaturesToLayer('managed_turf_areas', managedTurfAreasFeatureLayer, locationDataMap);
        addFeaturesToLayer('our_sdg', unSDGFeatureLayer, locationDataMap);
        addFeaturesToLayer('vegetation_and_habitat', vegetationAndHabitatFeatureLayer, locationDataMap);
        addFeaturesToLayer('health_and_safety', healthAndSafetyLayerFeatureLayer, locationDataMap);
        addFeaturesToLayer('development_and_growth', developmentGrowthLayerFeatureLayer, locationDataMap);
        addFeaturesToLayer('education', qualityEducationLayerFeatureLayer, locationDataMap);
        addFeaturesToLayer('gender_equality', genderEqualityLayerFeatureLayer, locationDataMap);
        addFeaturesToLayer('partnerships', partnershipLayerFeatureLayer, locationDataMap);
        addFeaturesToLayer('reduce_inequality', reduceInequalityLayerFeatureLayer, locationDataMap);
        addFeaturesToLayer('heritage', heritageLayerFeatureLayer, locationDataMap);
    }


    const addFeaturesToLayer = (type, layer, dataMap) => {
        const geojson = new GeoJSON();
        const source = layer.getSource();
        source.clear();
        const locations = dataMap[type] || [];
        locations.forEach(location => {
            if (!_.isEmpty(location.geom)) {
                let features = geojson.readFeatures(location.geom);
                features = features.map(f => {
                    f.set('title', location.title);
                    f.set('type', type);
                    f.set('date', f.get('date') || location.created_at);
                    f.set('id', location.site_value_id);
                    return f;
                });
                source.addFeatures(features);
                source.refresh();
            }
        });
    }

    const setRecordViewInnerData = async (data) => {
        const template = await resolveTemplate(`reports/site-values/location_report_en.html`);
        const compiledTemplate = handlebars.compile(template);
        const input = compiledTemplate(data);
        setRecordViewInner(input);
    }

    const getTumbnail = (key, downloadUrl) => {
        const extention = key.split('.').pop().toLowerCase();
        switch(extention){
            case ("jpg"):
            case ("jpeg"):
            case ("png"):
                return downloadUrl;
            case ("pdf"):
                return "/icons/thumbnails/pdf.png";
            default:
                return "/icons/thumbnails/file.png";
        }

    }

    const getRecordViewerData = async (orgId, orgUnitId, certificate, siteValueId) => {

        const [siteValueInfo, comments, activities] = await Promise.all([
            getSiteValueInfo(orgId, orgUnitId, siteValueId, certificate),
            getComments(orgId, siteValueId),
            getActivities(orgId, siteValueId)
          ]);

        const { title, type, description, created_at, attachments, geom } =  siteValueInfo;
        let locationAttachments = undefined;
        if(Object.keys(geom).length !== 0) {
            const parsedGeom = JSON.parse(geom);
            locationAttachments = parsedGeom.type === 'Feature' ?  parsedGeom.properties.attachments :  parsedGeom.features[0].properties.attachments;
        }

        let attachmentObjs = [];
        if (attachments) {
            const proms = attachments.map(async (key) => {
                const attachment = await getAttachmentSignedUrl(orgId, siteValueId, key);
                if (attachment) {
                    const downloadUrl = attachment.url;
                    const im = {
                        downloadUrl : downloadUrl,
                        thumbnailUrl :getTumbnail(key, downloadUrl),
                        name : key
                    }
                    return im;
                }
            });
            attachmentObjs = await Promise.all(proms);
        }
        let locationAttachmentObjs = undefined;
        let generalAttachments = undefined;
        if(locationAttachments) {
            locationAttachmentObjs = attachmentObjs.filter(x => locationAttachments.includes(x.name) );
            generalAttachments = attachmentObjs.filter(x => !locationAttachments.includes(x.name) );
        }

        if(comments){
            comments.map((comment) => {
                comment.added = formatDateTimeToDisplay(comment.createdAt)
                return comment;
            });
        }

        if(activities){
            activities.map((activity) => {
                activity.added = formatDateTimeToDisplay(activity.createdAt)
                return activity;
            });
        }

        const response = {
            siteValue: {
                title,
                type : SITE_VALUE_TYPES.find(val => val.key === type).value,
                description,
                createdAt: formatDateTimeToDisplay(created_at)
            },
            attachments: generalAttachments,
            locationAttachments : locationAttachmentObjs,
            comments,
            activities
        };

        return response;
    }

    const downloadReviewRecordURL = async (orgId, orgUnitId, certificate, siteValueRecordId) => {

        try {
            setSelectedSiteValueId(siteValueRecordId);
            setIsLoading(true);
            setRecordViewerVisible(true);
            const data = await getRecordViewerData(orgId, orgUnitId, certificate, siteValueRecordId);
            await setRecordViewInnerData(data);
            setIsLoading(false);
            setSelectedSiteValueId();
        } catch (e) {
            console.error(e)
            setIsLoading(false);
            message.error('Error in getting document content. Retry later');

        }

    }

    const columns = [{
        title: 'Name',
        dataIndex: 'title',
        render: (_, record) => record.title
    },
    {
        title: 'Date Added',
        dataIndex: 'created_at',
        render: (_, record) => formatDateToDisplay(record.created_at)
    },
    {
        title: 'Type',
        dataIndex: 'type',
        render: (_, record) => {
            const siteValueRecord = SITE_VALUE_TYPES.find(val => val.key === record.type);
            if(siteValueRecord) {
                return (siteValueRecord.value)
            }
        }
    },
    {
        title: '',
        dataIndex: '',
        render: (_, record) =>
        (<Button type="link" onClick={() => { downloadReviewRecordURL(orgId, orgUnitId, certificate, record.site_value_id)}}
            icon={
                isLoading && selectedSiteValueId === record.site_value_id ? (
                    <Spin size="small"
                    />
                ) : null
            }> &nbsp;View </Button>)


    }]

    return (
        <Card title="S. M. A. R. T. Map">

            {
                recordViewerVisible && <RecordViewerModal
                    onClose={() => setRecordViewerVisible(false)}
                    reportData={recordViewInner}
                    isLoading={isLoading}
                    reportName = "site_value_report"
                />
            }
            <div>
                <React.Fragment>
                    <div className='epar__hazard'>
                        <div className="pure-g epar__content__taskbar">
                            <div className="pure-u-1-3">
                            </div>
                            <div className="pure-u-2-3">
                            </div>
                        </div>

                        <div className="pure-g epar__tasklist">
                            <div className="pure-u-1">
                                <div ref={mapRef} />
                            </div>
                        </div>

                        <div className="pure-g epar__content__taskbar epar__hazard__map__legend">
                            {SITE_VALUE_TYPES.map(site =>
                            (
                                <Col key={site.key} lg={6} md={8} sm={12} xs={24}>
                                    <img src={`/icons/site-value/${site.bodyIcon}`} alt={site.value} />
                                    <div className="pure-u-5-6">&nbsp;&nbsp;{site.value}</div>
                                </Col>
                            )
                            )}

                        </div>

                    </div>
                </React.Fragment>
                <div className="table-wrapper" style={{ marginTop: 20 }}>
                    <Table dataSource={siteValuesList} columns={columns} />
                </div>
            </div>
        </Card>
    );

}